import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import Layout from "../../components/Layout";
import { connect } from 'react-redux'
import { getTerms } from '../../reducers/Terms/actions'
import { urlDescription } from '../../utils/pageDescription'
import SideNav from "../../components/SideNav/SideNav";
import "./styles.scss";

const Gdpr = ({ terms, getTerms }) => {
  const [currentTerm, setCurrentTerm] = useState("")

  useEffect(() => {
    getTerms({ type: 'gdpr' })
  }, [getTerms])

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="terms-wrapper">
        <div className="terms-title-wrapper">
          <div className="discount-terms-page-title mt-5">
            <h1 className="font-weight-bold">{terms && terms[0].title}</h1>
          </div>
        </div>
        <div className="terms-container">
          <Row className="mt-5">
            <Col className='mb-5 sidenav-menu-col' xs={12} md={4} lg={4}>
              {terms && terms[0].sections
                ? <SideNav
                  terms={terms[0]}
                  setCurrentTerm={setCurrentTerm}
                  termTitle={currentTerm}
                  gdpr />
                : null
              }
            </Col>
            <Col className="terms-of-use-col" xs={12} md={8} lg={8}>
              <div className="discount-terms mt-2">
                {terms && terms[0].sections &&
                  terms[0].sections.map((term, i) => {
                    return (
                      <div
                        name={`${term.id}`}
                        key={term.id}
                        className="mb-5"
                      >
                        <h3
                          className="h4"
                          id={term.id}
                        >
                          {term.title}
                        </h3>
                        <p
                          dangerouslySetInnerHTML={{ __html: term.description }}
                          className='mt-3'
                        />
                      </div>
                    )
                  })
                }
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    terms: state.terms.common
  };
};

const mapDispatchToProps = {
  getTerms
};

export default connect(mapStateToProps, mapDispatchToProps)(Gdpr);
